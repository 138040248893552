import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useEffect, useRef } from "react";

//
import "./footer.scss";

export interface Props {}

const Footer: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      socialMenu: allWpMenu(filter: { slug: { eq: "social-menu" } }) {
        nodes {
          id
          menuItems {
            nodes {
              label
              url
              target
            }
          }
        }
      }
      wp {
        themeGeneralSettings {
          themeSettings {
            address
            googleMapsLink
            email
          }
        }
      }
    }
  `);
  const { address, googleMapsLink, email } =
    data.wp.themeGeneralSettings.themeSettings;

  return (
    <div className="footer">
      <div className="footer__info">
        <a href={googleMapsLink} target="_blank">{address}</a>
        <br />
        <a href="mailto:info@clubmichel.net" target="_blank">{email}</a>
      </div>
      <div className="footer__social">
        {data.socialMenu.nodes.map((item: any, key: any) => {
          return item.menuItems.nodes.map((item: any, key: any) => {
            return (
              <div className="footer__social-items" key={key}>
                <a href={item.url} target={item.target} >
                  {item.label}
                </a>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};

export default Footer;
