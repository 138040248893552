import React from "react";
import { graphql } from "gatsby";
import "./page-tempate.scss";
import "styles/shared/_form.scss"
import GravityFormForm from "gatsby-gravityforms-component";
import Footer from "components/footer/footer";
import ImageFlip from "components/imageFlip/imageFlip";
import isMobile from "ismobilejs";

export const query = graphql`
  query ($id: String, $formId: Int) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      imageFlip {
        imageFlip {
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
    allGfForm(filter: { formId: { eq: $formId } }) {
      edges {
        node {
          ...GravityFormComponent
        }
      }
    }
  }
`;

interface pageTemplateProps {
  data: {
    wpPage: {
      id: string;
      title: string;
      content: string;
      imageFlip: any;
    };
    allGfForm: {
      edges: any;
    };
  };
  pageContext: any;
}

const PageTemplate: React.FC<pageTemplateProps> = (props) => {
  const page = props.data.wpPage;
  const images = props.data.wpPage.imageFlip.imageFlip;

  const handleSuccess = () => {
    console.log('success')
  };

  const handleError = (e: any) => {
    console.log('error', e)
  };

  return (
    <div className="page">
      <div className="page-inner">
        <div className="page-content">
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
        {!!props.data.allGfForm && !!props.pageContext.formId && (
          <GravityFormForm
            id={props.pageContext.formId}
            formData={props.data.allGfForm}
            lambda={process.env.LAMBDA_ENDPOINT}
            successCallback={handleSuccess}
            errorCallback={handleError}
          />
        )}
        </div>
      </div>
      <Footer />
      {!!images && <ImageFlip images={images} />}
    </div>
  );
};

export default PageTemplate;
